define("discourse/plugins/poll/discourse/widgets/discourse-poll", ["exports", "discourse/widgets/render-glimmer", "discourse/widgets/widget", "discourse/plugins/poll/discourse/components/modal/poll-ui-builder", "@ember/template-factory"], function (_exports, _renderGlimmer, _widget, _pollUiBuilder, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-poll", {
    tagName: "div",
    buildKey: attrs => `poll-${attrs.id}`,
    services: ["dialog"],
    buildAttributes(attrs) {
      let cssClasses = "poll-outer";
      if (attrs.poll.chart_type === _pollUiBuilder.PIE_CHART_TYPE) {
        cssClasses += " pie";
      }
      return {
        class: cssClasses,
        "data-poll-name": attrs.poll.name,
        "data-poll-type": attrs.poll.type
      };
    },
    html(attrs) {
      return [new _renderGlimmer.default(this, "div.poll", (0, _templateFactory.createTemplateFactory)(
      /*
        <Poll @attrs={{@data.attrs}} @preloadedVoters={{@data.preloadedVoters}} @options={{@data.options}} />
      */
      {
        "id": "QjKWVOq9",
        "block": "[[[8,[39,0],null,[[\"@attrs\",\"@preloadedVoters\",\"@options\"],[[30,1,[\"attrs\"]],[30,1,[\"preloadedVoters\"]],[30,1,[\"options\"]]]],null]],[\"@data\"],false,[\"poll\"]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/poll/discourse/widgets/discourse-poll.js",
        "isStrictMode": false
      }), {
        attrs,
        preloadedVoters: attrs.poll.preloaded_voters,
        options: attrs.poll.options
      })];
    }
  });
});